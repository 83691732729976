.invalid-error {
  color: rgb(207, 65, 65);
  font-weight: bold;
  font-size: 14px;
}

.subheading {
  padding: 5, 0, 5, 0;
  margin-top: 10px
}

.submitBtn {
  margin-top: 20px;
}
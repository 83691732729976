.ship-accordion {
  width: 100%;
  min-width: 350px;
  max-width: 1000px;
}

.container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.wrap-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

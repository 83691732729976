.ship-select {
  width: 350px;
  min-width: 100px;
  margin-right: 20px;
}

.year-select {
    width: 350px;
    margin-left: 20px;
    max-width: 350px;
}

.subheader {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    max-width: 600px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

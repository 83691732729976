.tableContainer thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}

th,
td {
  padding: 5px 5px;
  border-left: 1px solid rgb(150, 149, 149);
  border-right: 1px solid rgb(150, 149, 149);
}
th {
  background: rgb(73, 72, 72);
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableContainer {
  height: 80vh;
  overflow-y: auto;
  width: auto;
}

.tableContainerModal thead th {
  position: sticky;
  top: 0;
}

.tableContainerModal {
  width: 100%;
  height: 300px;
  margin-top: 100px;
  overflow: auto;
}

.tableEditBtn {
  margin: 5px;
  padding: 8px;
}

.tableDelBtn {
  margin: 5px;
  padding: 8px;
  background-color: #e54d4d;
  border-color: #c63232;
  color: #c63232;
}

.tableDelBtn:hover {
  background-color: #c63232;
  border-color: #c63232;
}

.tableDelBtn:focus {
  background-color: #c63232;
  border-color: #c63232;
}

.tableDisplayBtn {
  margin: 5px;
  padding: 8px;
  background-color: #706a6a;
  border-color: #0e0d0d;
  color: #625f5f;
}

.tableDisplayBtn:hover {
  background-color: #8a8484;
  border-color: #353131;
}

.tableDisplayBtn:focus {
  background-color: #3a3636;
  border-color: #3f3d3d;
}

.tableInvoiceBtn {
  margin: 5px;
  padding: 8px;
  background-color: #17a2b8;
  border-color: #1d9bae;
  color: #625f5f;
}

.tableInvoiceBtn:hover {
  background-color: #1d9bae;
  border-color: #1d9bae;
}

.tableInvoiceBtn:focus {
  background-color: #1d9bae;
  border-color: #1d9bae;
}

.wrap-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  margin-left: 20px;
  margin-top: 12px;
}

.error-text {
  color: red;
}
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid;
}

th,
td {
  padding: 5px 5px;
  border-left: 1px solid rgb(150, 149, 149);
  border-right: 1px solid rgb(150, 149, 149);
}
th {
  background: rgb(73, 72, 72);
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableContainerModal thead th {
  position: sticky;
  top: 0;
}

.tableContainerModal {
  width: 100%;
  height: 150px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: auto;
}

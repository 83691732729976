.addBtn {
  color: white;
  float: right;
}

.addBtn:hover {
  color: white;
}

.addBtn:focus {
  color: white;
}

.changeBtn {
  color: white;
  margin-right: 5px;
  background-color: #17a2b8;
  border-color: #1d9bae;
  float: right;
}

.changeBtn:hover {
  color: white;
  background-color: #1d9bae;
  border-color: #1d9bae;
}

.changeBtn:focus {
  color: white;
  background-color: #1d9bae;
  border-color: #1d9bae;
}

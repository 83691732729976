.submitBtn {
  float: right;
  color: white;
}

.submitBtn:hover {
  color: white;
}

.submitBtn:focus {
  color: white;
}
.invalid-error {
  color: rgb(207, 65, 65);
  font-weight: bold;
  font-size: 14px;
}

.submitBtn {
  margin-top: 20px;
}

.btn {
  color: white;
}

.btn:hover {
  color: white;
}

.btn:focus {
  color: white;
}

.margin {
  margin-right: 5px;
}
.header {
  font-size: 15px;
  font-weight: bold;
  padding: 4px;
  padding-left: 50px;
}

.items {
  font-size: 15px;
  padding: 5px;
}

$sidebar-bg-color: #f8f9fa;
$sidebar-color: #000000;
$highlight-color: #107edf;
$submenu-bg-color: #e8eaed;
$submenu-bg-color-collapsed: #e8eaed;
$icon-bg-color: #107edf;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0px 5px 0px 5px;
}

.collapse-btn {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f8f9fa;
  width: 40px;
  transition: margin-left 1s;
}

main {
  display: flex;
  margin-top: 60px;
}

.main {
  transition: margin-left 0.3s;
  width: 100%;
  margin-right: 30px;
  overflow-x: hidden;
  height: 100vh;
}

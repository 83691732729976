.modalHeader {
  background-color: #1e88e5;
  color: white;
}

.tableContainer {
  width: 100%;
  height: 300px;
  margin-top: 100px;
}

.modalHeader {
  background-color: #1e88e5;
  color: white;
}

.btn {
  color: white;
}

.btn:hover {
  color: white;
}

.btn:focus {
  color: white;
}
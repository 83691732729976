.modalHeader {
  background-color: #1e88e5;
  color: white;
}

.header {
    font-size: 15px;
    font-weight: bold;
    padding: 4px;
    padding-left: 50px;
}

.items {
    font-size: 15px;
    padding: 5px;
   
}

.item-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item-wrapper:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
}

.wrapper {
    margin: auto;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.span-wrapper {
    display: flex;
}
